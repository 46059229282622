import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    render() {
        const {html} = this.props;
        return (
            <div className="footer">
                <p dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        );
    }
};



export default Footer;