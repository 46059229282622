import React, { Component } from 'react';

import './Backend.css';
import { Container, Row, Col } from 'react-bootstrap';

require('dotenv').config();

class Requests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      response: null,
      name: '',
      email: '',
      phone: '',
      message: '',
      isLoading: true,
      error: null,
      status: 200,
      isValid: false,
    }
  }

  fetchRequest(id) {
    // Where we're fetching data from api+
    fetch(process.env.REACT_APP_NODE_BACKEND + '/requests' + id, {
      method: 'GET',
      credentials: 'include',
    })
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();
      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          response: data,
          name: data.name,
          email: data.email,
          phone: data.phone,
          message: data.message,
          isLoading: false,
        })
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }


  componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id !== 'create') {
      this.fetchRequest("/" + params.id);
    } else {
      this.setState({
        isLoading: false,
        creating: true,
      });
    }
  }

  render() {
    const { message, name, email } = this.state;
    return (
      <Container>
        <div>
          <label htmlFor="siteTitle">Nafn</label>
          <Row><Col><input className="form-control" type="text" name="name" id="siteTitle" value={name} ></input></Col></Row>
          <label htmlFor="siteTitle">Email</label>
          <Row><Col><input className="form-control" type="text" name="name" id="siteTitle" value={email} ></input></Col></Row>
          <label htmlFor="message">Skilaboð</label>
          <Row> <Col><textarea className="form-control" rows="5" value={message} name="message"></textarea></Col></Row>

        </div>
      </Container>
    );
  }

}

export default Requests;