import React, { Component } from 'react';

import './Backend.css';
import { Container, Row, Col } from 'react-bootstrap';

require('dotenv').config();

class ViewBooking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      response: null,
      name: '',
      ssn: '',
      email: '',
      phone: '',
      car: '',
      location: '',
      pickUpDate: '',
      returnDate: '',
      refNumber: '',
      price: '',
      extras: '',
      dateCreated: undefined,
      isLoading: true,
      error: null,
      status: 200,
      isValid: false,
    }
  }

  fetchRequest(id) {
    // Where we're fetching data from api+
    fetch(process.env.REACT_APP_NODE_BACKEND + '/bookings' + id, {
      method: 'GET',
      credentials: 'include',
  })
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();
      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          response: data,
          name: data.name,
          ssn: data.SSN,
          email: data.email,
          phone: data.phone,
          car: data.car,
          location: data.location,
          pickUpDate: data.pickUpDate,
          returnDate: data.returnDate,
          refNumber: data.refNumber,
          price: data.price,
          extras: data.extras,
          dateCreated: data.dateCreated,
          isLoading: false,
        })
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }


  componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id !== 'create') {
      this.fetchRequest("/" + params.id);
    } else {
      this.setState({
        isLoading: false,
        creating: true,
      });
    }
  }

  createExtraList(item) {
    return (item.name + " ");
  }

  render() {
    const { name, email, phone, ssn, refNumber, dateCreated, car, location, pickUpDate, returnDate, extras, price } = this.state;
    var tryggingar = '';
    var aukahlutir = '';
    if (extras ) {
      tryggingar = extras.insuranceSelected.map(this.createExtraList);
      aukahlutir = extras.extrasSelected.map(this.createExtraList);
    }
    return (
      <Container style={{ padding: '20px' }}>
        <Row><Col><h2>Skoða bókun</h2></Col></Row>
        <label htmlFor="name">Nafn</label>
        <Row><Col><input className="form-control" type="text" name="name" id="name" value={name} readOnly></input></Col></Row>
        <label htmlFor="ssn">Kennitala</label>
        <Row><Col><input className="form-control" type="text" name="ssn" id="ssn" value={ssn} readOnly></input></Col></Row>
        <label htmlFor="email">Email</label>
        <Row><Col><input className="form-control" type="text" name="email" id="email" value={email} readOnly></input></Col></Row>
        <label htmlFor="phone">Sími</label>
        <Row><Col><input className="form-control" type="text" name="phone" id="phone" value={phone} readOnly></input></Col></Row>
        <label htmlFor="refNumber">Dax númer</label>
        <Row><Col><input className="form-control" type="text" name="refNumber" id="refNumber" value={refNumber} readOnly></input></Col></Row>
        <label htmlFor="dateCreated">Dagsetning bókunar</label>
        <Row><Col><input className="form-control" type="text" name="dateCreated" id="dateCreated" value={dateCreated} readOnly></input></Col></Row>
        <label htmlFor="car">Bíll</label>
        <Row><Col><input className="form-control" type="text" name="car" id="car" value={"Klasi: " + car.carClass + " Nafn: " + car.name + " Bílnúmer: " + car.carId} readOnly></input></Col></Row>
        <label htmlFor="location">Staðsetning</label>
        <Row><Col><input className="form-control" type="text" name="location" id="location" value={location.locationCode + ' ' + location.address + ' ' + location.postCode + ' ' + location.name} readOnly></input></Col></Row>
        <label htmlFor="pickupDate">Pickup tími</label>
        <Row><Col><input className="form-control" type="text" name="pickupDate" id="pickupDate" value={pickUpDate} readOnly></input></Col></Row>
        <label htmlFor="returnDate">Return tími</label>
        <Row><Col><input className="form-control" type="text" name="returnDate" id="returnDate" value={returnDate} readOnly></input></Col></Row>
        <label htmlFor="price">Verð</label>
        <Row><Col><input className="form-control" type="text" name="price" id="price" value={price !== undefined ? ("Bíll: " + price.carPrice + " Tryggingar: " + price.insurancePrice + " Aukahlutir: " + price.extraPrice) : 0} readOnly></input></Col></Row>
        <label htmlFor="insurance">Tryggingar</label>
        <Row><Col><input className="form-control" type="text" name="insurance" id="insurance" value={tryggingar} readOnly></input></Col></Row>
        <label htmlFor="extras">Aukahlutir</label>
        <Row><Col><input className="form-control" type="text" name="extras" id="extras" value={aukahlutir} readOnly></input></Col></Row>
      </Container>
    );
  }

}

export default ViewBooking;