import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './Backend.css';
import ReactLoading from 'react-loading';
import Dropzone from 'react-dropzone';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import ImageService from '../../services/ImageService';

require('dotenv').config();

class EditSite extends Component {

    constructor(props) {
        super(props);

        this.state = {
            response: null,
            name: '',
            link: '',
            html: '',
            image_id: '',
            image_url: '',
            oldImage_url: '',
            metaTitle: '',
            metaKeywords: '',
            metaDescription: '',
            isLoading: true,
            isUploading: false,
            error: null,
            status: 200
        }
    }

    fetchSite(id) {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/sites' + id)
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    response: data,
                    name: data.name,
                    link: data.link,
                    html: data.html,
                    image_id: data.image_id,
                    image_url: data.image_url,
                    oldImage_url: data.image_url,
                    metaTitle: data.metaTitle ,
                    metaKeywords: data.metaKeywords ,
                    metaDescription: data.metaDescription ,
                    isLoading: false,
                    creating: false,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    createSite = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_NODE_BACKEND + '/sites', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                link: this.state.link,
                html: this.state.html,
                image_id: this.state.image_id,
                image_url: this.state.image_url,
                metaTitle: this.state.metaTitle,
                metaKeywords: this.state.metaKeywords,
                metaDescription: this.state.metaDescription,
            })
        })
            .then(response => {

                if (response.ok) {
                    return response.json();
                } else if (response.status === 409) {
                    throw Error("Síða með þessum link er nú þegar til");
                }
                else {
                    throw Error("Rejected with code " + response.status);
                }

            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    updateSite = (event) => {
        event.preventDefault();
        const { match: { params } } = this.props;
        fetch(process.env.REACT_APP_NODE_BACKEND + '/sites/' + params.id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                link: this.state.link,
                html: this.state.html,
                image_id: this.state.image_id,
                image_url: this.state.image_url,
                metaTitle: this.state.metaTitle,
                metaKeywords: this.state.metaKeywords,
                metaDescription: this.state.metaDescription,
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 409) {
                    throw Error("Síða með þessum link er nú þegar til");
                }
                else {
                    throw Error("Rejected with code " + response.body);
                }
            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    onSubmit = (event) => {
        if (this.state.isUploading) {
            event.preventDefault()
        } else {
            this.setState({ isSubmitted: true });
            if (this.state.creating) {

                this.createSite(event);
            } else {
                this.updateSite(event);
            }
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        if (params.id !== 'create') {
            this.fetchSite("/" + params.id);
        } else {
            this.setState({
                isLoading: false,
                creating: true,
            });
        }
    }

    componentWillUnmount() {
        let imageToDelete;
        if (!this.state.isSubmitted && this.state.image_url !== this.state.oldImage_url) {
            imageToDelete = this.state.image_url;
        } else if(this.state.image_url !== this.state.oldImage_url){
            imageToDelete = this.state.oldImage_url
        }
        if(imageToDelete) {ImageService.DeleteImage(imageToDelete); }
    }

    componentWillReceiveProps(newProps) {
        this.setState({ error: null, })
        this.fetchSite(newProps.match.url);
    }

    onNameChange = event => {
        this.setState({ name: event.target.value });
    }
    onLinkChange = event => {
        this.setState({ link: event.target.value });
    }
    onHtmlChange = event => {
        this.setState({ html: event.target.getContent() });
    }
    onMetaTitleChange = event => {
        this.setState({ metaTitle: event.target.value });
    }
    onMetaKeywordsChange = event => {
        this.setState({ metaKeywords: event.target.value });
    }
    onMetaDescriptionChange = event => {
        this.setState({ metaDescription: event.target.value });
    }

    async handleUploadImage(image) {
        this.setState({ isUploading: true });
        const folder = "sites"
        this.setState({ oldImage_url: this.state.image_url })
        await ImageService.AddImage(image, folder).then(ok => {
            this.setState({ image_id: ok.key, image_url: ok.location, isUploading: false })
        }, 
        err=> {
            this.setState({isUploading: false, error: err})
        })
    }

    render() {
        const { error, isLoading, isUploading, name, link, html, metaTitle, metaKeywords, metaDescription, status, creating, image_url } = this.state;
        if (status === 404) {
            return (<div className="Backend">
                <h1>404</h1>
            </div>)
        }
        else {
            return (
                <div className="Backend">
                    <Container>
                        <Row><Col><h1 className="Title">Thrifty sendibílaleiga {creating ? ('búa til síðu') : ('breyta síðu')}</h1></Col></Row>
                        {!isLoading && (html || creating) ? (

                            <div>
                                <form onSubmit={this.onSubmit}>
                                    <Tabs defaultActiveKey="site" id="SiteAddEditTab">
                                        <Tab eventKey="site" title="Síða">
                                            <Row>
                                                <Col>
                                                    {isUploading ? (<ReactLoading type={"spin"} color={"#5b5b5b"} height={100} width={150} />) : (<img src={image_url} alt={""} />)}
                                                </Col>
                                                <Col>
                                                    <Dropzone
                                                        onDrop={this.handleUploadImage.bind(this)}
                                                        accept="image/*"
                                                    >
                                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                            <div className="DropZone" {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {!isDragActive && 'Click here or drop a image to upload!'}
                                                                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                                                                {isDragReject && "File type not accepted, sorry!"}
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </Col>
                                            </Row>
                                            <label htmlFor="siteTitle">Nafn</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="name" id="siteTitle" value={name} onChange={this.onNameChange} ></input> </Col>
                                            </Row>

                                            <label htmlFor="siteHtml">Html</label>
                                            <Row>
                                                <Col>
                                                    <Editor id="siteHtml"
                                                        initialValue={html}
                                                        init={{
                                                            height: '400px',
                                                            plugins: 'autolink link image lists print preview code textcolor table',
                                                            menubar: "file edit insert view format table tools",
                                                            toolbar: 'formatselect | bold italic underline strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | code'
                                                        }}
                                                        onChange={this.onHtmlChange}
                                                    />
                                                </Col>
                                            </Row>

                                        </Tab>
                                        <Tab eventKey="settings" title="Stillingar">
                                            <label htmlFor="siteLink">Link</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="link" id="siteLink" value={link} onChange={this.onLinkChange}></input></Col>
                                            </Row>
                                            <label htmlFor="siteMetaTitle">Meta Title</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="meta-title" id="siteMetaTitle" value={metaTitle} onChange={this.onMetaTitleChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="siteMetaKeywords">Meta Keywords</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="meta-Keywords" id="siteMetaKeywords" value={metaKeywords} onChange={this.onMetaKeywordsChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="siteMetaDescription">Meta Description</label>
                                            <Row>
                                                <Col><textarea name="html" className="form-control" rows="10" id="siteMetaDescription" value={metaDescription} onChange={this.onMetaDescriptionChange}></textarea></Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                    <Row>
                                        <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                                        <Col xs={2}><button className="submitButton" type="submit">SUBMIT</button></Col>
                                    </Row>
                                </form>
                            </div>
                            // If there is a delay in data, let's let the user know it's loading
                        ) : (
                                <h3>Loading...</h3>
                            )}
                    </Container>
                </div>
            );
        }
    }
}

export default EditSite;