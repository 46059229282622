import React, { Component } from 'react';

import './Backend.css';
import { Container, Row, Col } from 'react-bootstrap';

require('dotenv').config();

class ExtrasAddEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      response: null,
      name: '',
      accessories: false,
      insurance: false,
      refNumber: '',
      price: '',
      amount: '',
      isLoading: true,
      error: null,
      status: 200
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id !== 'create') {
      this.setState({ id: params.id });
      this.fetchExtra("/" + params.id);
    } else {
      this.setState({
        isLoading: false,
        creating: true,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({ error: null, })
    this.fetchExtra(newProps.match.url);
  }

  fetchExtra(id) {
    // Where we're fetching data from api+
    fetch(process.env.REACT_APP_NODE_BACKEND + '/extras' + id)
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();
      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          response: data,
          name: data.name,
          accessories: data.accessories,
          insurance: data.insurance,
          refNumber: data.refNumber,
          price: data.price,
          amount: data.amount,
          isLoading: false,
          creating: false,
        })
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }

  createExtra = (event) => {
    event.preventDefault();
    fetch(process.env.REACT_APP_NODE_BACKEND + '/extras', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        name: this.state.name,
        accessories: this.state.accessories,
        insurance: this.state.insurance,
        refNumber: this.state.refNumber,
        price: this.state.price,
        amount: this.state.amount
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 412) {
          throw Error("Aukahlutir og Tilboð meiga ekki vera með sama gildi");
        } else if (response.status === 409) {
          throw Error("Þetta nafn er nú þegar til");
        } else {
          throw Error("Rejected with code " + response.status);
        }
      })
      .then(data => {
        this.props.history.goBack();;
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  updateExtra = (event) => {
    event.preventDefault();
    const { match: { params } } = this.props;
    fetch(process.env.REACT_APP_NODE_BACKEND + '/extras/' + params.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        name: this.state.name,
        accessories: this.state.accessories,
        insurance: this.state.insurance,
        refNumber: this.state.refNumber,
        price: this.state.price,
        amount: this.state.amount
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 412) {
          throw Error("Aukahlutir og Tilboð meiga ekki vera með sama gildi");
        } else if (response.status === 409) {
          throw Error("Þetta nafn er nú þegar til");
        } else {
          throw Error("Rejected with code " + response.body);
        }
      })
      .then(data => {
        this.props.history.goBack();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.creating) {
      this.createExtra(event);
    } else {
      this.updateExtra(event);
    }
  }

  onNameChange = event => {
    this.setState({ name: event.target.value });
  }

  onAccessoriesChange = event => {
    this.setState({ accessories: event.target.checked });
    this.setState({ insurance: false });
  }

  onInsuranceChange = event => {
    this.setState({ insurance: event.target.checked });
    this.setState({ accessories: false });
 }

  onPriceChange = event => {
    this.setState({ price: event.target.value });
  }
  onAmountChange = event => {
    this.setState({ amount: event.target.value });
  }
  onRefNumberChange = event => {
    this.setState({ refNumber: event.target.value })
  }

  render() {
    const { isLoading, creating, error, refNumber, price, name, insurance, accessories, amount } = this.state;

    return (
      <div className="Backend">
        <Container>
          <Row><Col><h1 className="Title">Thrifty sendibílaleiga {creating ? ('búa til aukahlut') : ('breyta aukahlut')}</h1></Col></Row>
          {!isLoading ? (
            <form onSubmit={this.onSubmit}>
              <Col>
                <label htmlFor="Name">Nafn</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="text" required name="name" id="Name" value={name} onChange={this.onNameChange} ></input> </Col>

                  <Col><input type="radio" name="radio" checked={accessories} id="accessories" value={accessories} onChange={this.onAccessoriesChange} /> <label htmlFor="accessories">Aukahlutir</label> </Col>
                  <Col><input type="radio" name="radio" checked={insurance} id="insurance" value={insurance} onChange={this.onInsuranceChange} /> <label htmlFor="insurance">Trygging</label> </Col>

                </Row>
                <label htmlFor="price">Verð</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="text" required name="price" id="price" value={price} onChange={this.onPriceChange}></input> </Col>
                </Row>
                <label htmlFor="amount">Magn</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="text" required name="amount" id="amount" value={amount} onChange={this.onAmountChange}></input> </Col>
                </Row>
                <label htmlFor="refNumber">Reference Number</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="text" name="refNumber" id="refNumber" onChange={this.onRefNumberChange} value={refNumber}></input> </Col>
                </Row>

              </Col>
              <Row>
                <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                <Col xs={2}><button className="submitButton" type="submit">SUBMIT</button></Col>
              </Row>

            </form>
          ) : (
              <h3>Loading...</h3>
            )}
        </Container>
      </div>
    )

  }
}

export default ExtrasAddEdit;