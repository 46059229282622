import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
    
    componentWillUpdate ({ location, history }) {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render () {
        return null;
    }
}

export default withRouter(GoogleAnalytics);