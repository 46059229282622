import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import './Header.css';

// The Header creates links that can be used to navigate
// between routes.

class NavigationBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            droppedDown: true,
        }
    }

  componentDidMount() {
    this.OnResize();
    window.addEventListener('resize', this.OnResize.bind(this));
  }

  OnResize() {
    if(window.innerWidth > 768) {
      this.setState({droppedDown: true});
    } else if (window.innerWidth < 768 && this.state.droppedDown) {
      this.setState({droppedDown: false});
    }
  }

  dropDownClick() {
    if(window.innerWidth < 768) {
      this.setState({droppedDown: !this.state.droppedDown});
    }
  }

    render() {
      return (
        <header>
            <nav>
                <button className="DropDown" onClick={this.dropDownClick.bind(this)}><FontAwesomeIcon size="2x" icon="bars" className="fontawsome"/></button>
                <ul className="NavHead" style={{display: this.state.droppedDown ? 'flex': 'none'}}>
                    <li className="NavHead" onClick={this.dropDownClick.bind(this)}><Link to='/flokkur/minni-sendibilar'>MINNI</Link></li>
                    <li className="NavHead" onClick={this.dropDownClick.bind(this)}><Link to='/flokkur/staerri-sendibilar'>STÆRRI</Link></li>
                    <li className="NavHead" onClick={this.dropDownClick.bind(this)}><Link to='/flokkur/sendibilar-reykjavik'>REYKJAVÍK</Link></li>
                    <li className="NavHead" onClick={this.dropDownClick.bind(this)}><Link to='/flokkur/sendibilar-keflavik'>KEFLAVÍK</Link></li>
                    <li className="NavHead" onClick={this.dropDownClick.bind(this)}><Link to='/allir-bilar'>ALLIR BÍLAR</Link></li>
                </ul>
            </nav>
          </header>
      )
    }
}
export default NavigationBar