import React, { Component } from 'react';

import './Main.css';

class BookingBar extends Component {
    render() {
        return (
            <div className="BookingBar">
                <div style={{
                    height: "100%",
                    backgroundImage: `url(${this.props.image})`,
                    "backgroundSize": "cover",
                    "backgroundPosition": "center center",
                    "backgroundRepeat": "no-repeat",
                }}>
                </div>
            </div>
        );
    }

};



export default BookingBar;