import React, { Component } from 'react';
import FrontCategory from './FrontCategory';
import { Container, Row, Col, } from 'react-bootstrap';

import './FrontCategory.css';

class FrontCategorySelector extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            data: null,
            isLoading: true,
            error: null,
            status: 200,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchCategories();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchCategories() {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/categories')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this._isMounted && this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this._isMounted && this.setState({
                    data: data,
                    isLoading: false,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    createListItem(item) {
        return (
            <Col xs={12} sm={12} md={6} xl={4} key={item._id} style={{ margin: 0, padding: 0 }}><FrontCategory category={item} /></Col>
        );
    }

    render() {
        const { error, isLoading, data } = this.state;

        if (!isLoading && data) {
            var categories = data.sort((a, b) => a.order - b.order).map(this.createListItem.bind(this));
        }
        return (
            <div>
                <Container fluid={true} style={{ margin: 0, padding: 0 }}>
                    {error ? <p className="Error">Oops eitthvað fór úrskeiðis hér {error.message}</p> : null}
                    <Row style={{ margin: 0, padding: 0 }}>
                        {categories}
                    </Row>
                </Container>
            </div>
        );
    }
};

export default FrontCategorySelector;