import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import './Main.css';

import AuthCheck from '../Admin/AuthCheck'

import Backend from '../Admin/Backend';
import InfoSite from './InfoSite';
import FrontPage from '../Front/FrontPage';
import BackendList from '../Admin/BackendList';
import AddEditSite from '../Admin/SiteAddEdit';
import AddEditCategory from '../Admin/categoryAddEdit';
import AddEditLocation from '../Admin/LocationAddEdit'
import AddEditCar from '../Admin/CarAddEdit';
import AddEditPrice from '../Admin/PriceAddEdit';
import Category from './Category';
import AllCars from './AllCars';
import QuestionAndAnswere from './QuestAndAns';
import Requests from '../Admin/Requests';
import Booking from './Booking';
import AddEditOffer from '../Admin/OffersAddEdit';
import BookingInfo from "./BookingInfo";
import BookingConfirm from "./BookingConfirm";
import Login from "../Admin/Login";
import AddEditExtras from "../Admin/ExtrasAddEdit";
import ViewBookings from "../Admin/ViewBooking";
import UserAddEdit from "../Admin/UserAddEdit";
import NoMatch from "./NoMatch";


class Main extends Component {
    render() {
        const { main } = this.props;
        return (
            <div className="Main">
                <Switch>
                    <Route exact path="/404.html" component={NoMatch} />
                    <Redirect from="/is/sendibilaleiga/:path" to="/:path" />
                    <Redirect from="/is/:path" to="/:path" />
                    <Redirect from="/is" to="/" />
                    <Route exact path="/" component={() => <FrontPage main={main} />} />
                    <Route path="/admin/login" component={Login} />
                    <Route exact path="/admin" component={AuthCheck(Backend)} />
                    <Route exact path="/admin/:link" component={AuthCheck(BackendList)} />
                    <Route exact path="/admin/cars/:id" component={AuthCheck(AddEditCar)} />
                    <Route exact path="/admin/sites/:id" component={AuthCheck(AddEditSite)} />
                    <Route exact path="/admin/categories/:id" component={AuthCheck(AddEditCategory)} />
                    <Route exact path="/admin/locations/:id" component={AuthCheck(AddEditLocation)} />
                    <Route exact path="/admin/requests/:id" component={AuthCheck(Requests)} />
                    <Route exact path="/admin/prices/:id" component={AuthCheck(AddEditPrice)} />
                    <Route exact path="/admin/offers/:id" component={AuthCheck(AddEditOffer)} />
                    <Route exact path="/admin/extras/:id" component={AuthCheck(AddEditExtras)} />
                    <Route exact path="/admin/bookings/:id" component={AuthCheck(ViewBookings)} />
                    <Route exact path="/admin/users/:id" component={AuthCheck(UserAddEdit)} />
                    <Route exact path="/allir-bilar" component={AllCars} />
                    <Route exact path="/flokkur/:link" component={Category} />
                    <Route exact path="/hafa-samband" component={QuestionAndAnswere} />
                    <Route exact path="/til-leigu-sendibilar" component={Booking} />
                    <Route exact path="/til-leigu-sendibilar/upplysingar" component={BookingInfo} />
                    <Route exact path="/til-leigu-sendibilar/stadfesting" component={BookingConfirm} />
                    <Redirect  path="/litlir-sendibilar" to="/flokkur/litlir-sendibilar" />
                    <Redirect  path="/storir-sendibilar" to="/flokkur/storir-sendibilar" />
                    <Redirect  path="/midstaerd-sendibilar" to="/flokkur/midstaerd-sendibilar" />
                    <Redirect  path="/beinskiptir" to="/flokkur/beinskiptir" />
                    <Redirect  path="/sjalfskiptir" to="/flokkur/sjalfskiptir" />
                    <Redirect  path="/is/litlir-sendibilar" to="/flokkur/litlir-sendibilar" />
                    <Redirect  path="/is/storir-sendibilar" to="/flokkur/storir-sendibilar" />
                    <Redirect  path="/is/midstaerd-sendibilar" to="/flokkur/midstaerd-sendibilar" />
                    <Redirect  path="/is/beinskiptir" to="/flokkur/beinskiptir" />
                    <Redirect  path="/is/sjalfskiptir" to="/flokkur/sjalfskiptir" />
                    <Route exact path="/:link" component={InfoSite} />
                    <Route path="*" component={NoMatch} status={404}/>
                    <Route component={NoMatch} />
                </Switch>
            </div>
        );
    }
};



export default Main;