import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import BookingBar from './BookingBar';
import NoMatch from "./NoMatch";

require('dotenv').config();

class InfoSite extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            html: "",
            name: "",
            image: "",
            metaTitle: null,
            metaKeywords: null,
            metaDescription: null,
            error: null,
            status: 200
        }
    }

    fetchSite(path) {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/sites' + path)
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();

            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    html: data.html,
                    name: data.name,
                    image_id: data.image_id,
                    image_url: data.image_url,
                    metaTitle: data.metaTitle,
                    metaKeywords: data.metaKeywords,
                    metaDescription: data.metaDescription,
                    isLoading: false,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        this.fetchSite("/" + params.link);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ error: null, })
        this.fetchSite(newProps.match.url);
    }


    render() {
        const { html, name, error, isLoading, status, metaTitle, metaDescription, metaKeywords, image_url } = this.state;
        if (status === 404) {
            return (<NoMatch />)
        }
        else {
            return (
                <div className="InfoSite">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{metaTitle}</title>
                        <meta name="keywords" content={metaKeywords} />
                        <meta name="description" content={metaDescription} />
                    </Helmet>
                    <BookingBar image={image_url} />
                    {error ? <p className="Error">Oops eitthvað fór úrskeiðis hér {error.message}</p> : null}
                    {!isLoading ? (
                        <div className="infoSiteMain">
                            <h1>{name}</h1>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </div>
                        // If there is a delay in data, let's let the user know it's loading
                    ) : (
                            <h3>Loading...</h3>
                        )}

                </div>
            );
        }
    }
}

export default InfoSite;