import React, { Component } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import './Backend.css';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

require('dotenv').config();

class LocationAddEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            response: null,
            name: '',
            address: '',
            postCode: '',
            openingHours: {},
            hollidays: [],
            description: '',
            refNumber: '',
            isLoading: true,
            error: null,
            status: 200,
            order: 0,
        }
    }

    fetchSite(id) {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/locations' + id)
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    response: data,
                    name: data.name,
                    address: data.address,
                    description: data.description,
                    postCode: data.postCode,
                    openingHours: data.openingHours,
                    hollidays: data.hollidays,
                    refNumber: data.refNumber,
                    isLoading: false,
                    creating: false,
                    order: data.order,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    createLocation = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_NODE_BACKEND + '/locations', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                address: this.state.address,
                description: this.state.description,
                postCode: this.state.postCode,
                openingHours: this.state.openingHours,
                hollidays: this.state.hollidays,
                refNumber: this.state.refNumber,
                order: this.state.order,
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 409) {
                    throw Error("Opnunar tími með þessu nafni er nú þegar til");
                }
                else {
                    throw Error("Rejected with code " + response.status);
                }

            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    updateLocation = (event) => {
        event.preventDefault();
        const { match: { params } } = this.props;
        fetch(process.env.REACT_APP_NODE_BACKEND + '/locations/' + params.id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                address: this.state.address,
                description: this.state.description,
                postCode: this.state.postCode,
                openingHours: this.state.openingHours,
                hollidays: this.state.hollidays,
                refNumber: this.state.refNumber,
                order: this.state.order,
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 409) {
                    throw Error("Opnunar tími með þessu nafni er nú þegar til");
                }
                else {
                    throw Error("Rejected with code " + response.status);
                }
            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    onSubmit = (event) => {
        if (this.state.creating) {
            this.createLocation(event);

        } else {
            this.updateLocation(event);
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        if (params.id !== 'create') {
            this.fetchSite("/" + params.id);
        } else {
            this.setState({
                isLoading: false,
                creating: true,
            });
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({ error: null, })
        this.fetchSite(newProps.match.url);
    }

    onNameChange = event => {
        this.setState({ name: event.target.value });
    }
    onAddressChange = event => {
        this.setState({ address: event.target.value });
    }
    onDescriptionChange = event => {
        this.setState({ description: event.target.value });
    }
    onPostCodeChange = event => {
        this.setState({ postCode: event.target.value });
    }
    onRefNumberChange = event => {
        this.setState({ refNumber: event.target.value });
    }
    onWeekdaysOpenChange = event => {
        let newTime = moment(event._d).format('HH:mm');
        this.setState({ openingHours: { ...this.state.openingHours, 'weekDaysOpen': newTime } })
    }
    onWeekDaysCloseChange = event => {
        let newTime = moment(event._d).format('HH:mm');
        this.setState({ openingHours: { ...this.state.openingHours, 'weekDaysClose': newTime } })
    }
    onWeekdaysClosedChange = event => {
        this.setState({ openingHours: { ...this.state.openingHours, 'weekDaysClosed': event.target.checked } })
    }
    onSatDaysOpenChange = event => {
        let newTime = moment(event._d).format('HH:mm');
        this.setState({ openingHours: { ...this.state.openingHours, 'saturdayOpen': newTime } })
    }
    onSatDaysCloseChange = event => {
        let newTime = moment(event._d).format('HH:mm');
        this.setState({ openingHours: { ...this.state.openingHours, 'saturdayClose': newTime } })
    }
    onSatDaysClosedChange = event => {
        this.setState({ openingHours: { ...this.state.openingHours, 'saturdayClosed': event.target.checked } })
    }
    onSunDaysOpenChange = event => {
        let newTime = moment(event._d).format('HH:mm');
        this.setState({ openingHours: { ...this.state.openingHours, 'sundayOpen': newTime } })
    }
    onSunDaysCloseChange = event => {
        let newTime = moment(event._d).format('HH:mm');
        this.setState({ openingHours: { ...this.state.openingHours, 'sundayClose': newTime } })
    }
    onSunDaysClosedChange = event => {
        this.setState({ openingHours: { ...this.state.openingHours, 'sundayClosed': event.target.checked } })
    }
    onOrderChange = event => {
        if (!isNaN(event.target.value)) {
            this.setState({
                order: event.target.value,
                error: null,
            });
        }
    }


    changeHolliday = (event, index, name) => {
        let tmp = this.state.hollidays;
        if (event.target === undefined) {
            let newTime = moment(event._d).format('HH:mm');
            tmp[index][name] = newTime;
        }
        else if (event.target.type === 'checkbox') {
            tmp[index][event.target.name] = event.target.checked
        } else {
            tmp[index][event.target.name] = event.target.value;
        }
        this.setState({
            hollidays: tmp
        })
    }

    generateHollidayForm() {
        let holliDayForm = [];
        for (let index = 0; index < this.state.hollidays.length; index++) {
            const element = this.state.hollidays[index];
            holliDayForm.push(<Row key={index + 'name'}>
                <Col xs={2}><label htmlFor="weekDaysOpen">Dagur</label></Col>
                <Col xs={2}><label htmlFor="weekDaysClose" >Mánuður</label></Col>
                <Col xs={2}><label htmlFor="weekDaysClosed" >Opnar</label></Col>
                <Col xs={2}><label htmlFor="weekDaysClosed" >Lokar</label></Col>
                <Col xs={2}><label htmlFor="weekDaysClosed" >Lokað</label></Col>
            </Row>)
            holliDayForm.push(<Row key={index}>
                <Col xs={2}><input name="day" type="number" value={element.day} onChange={(event) => { this.changeHolliday(event, index, 'day') }} /> </Col>
                <Col xs={2}><input name="month" type="number" value={element.month} onChange={(event) => { this.changeHolliday(event, index, 'month') }} /> </Col>
                <Col xs={2}><TimePicker id={index + "open"} name={"open"} defaultValue={moment()} allowEmpty={false} showSecond={false} value={!element.open ? null : moment.utc(element.open, 'HH:mm')} onChange={(event) => { this.changeHolliday(event, index, 'open') }} /> </Col>
                <Col xs={2}><TimePicker id={index + "close"} name={"close"} defaultValue={moment()} allowEmpty={false} showSecond={false} value={!element.close ? null : moment.utc(element.close, 'HH:mm')} onChange={(event) => { this.changeHolliday(event, index, 'close') }} /> </Col>
                <Col xs={2}><input name="closed" type="checkbox" checked={element.closed} onChange={(event) => { this.changeHolliday(event, index, 'closed') }} /> </Col>
                <Col xs={2}><button type="button" onClick={(event) => { this.removeHollday(event, index) }}>fjarlægja</button></Col>
            </Row>);
        }
        return holliDayForm;
    }

    removeHollday = (e, index) => {
        this.setState(state => {
            let hollidays = state.hollidays;
            hollidays.splice(index, 1);
            return {
                hollidays,
            };
        });
    }

    addHolliday = () => {
        const day = {
            "closed": true,
            "day": 1,
            "month": 1,
            "open": "00:00",
            "close": "00:01"
        }
        this.setState(state => {
            const hollidays = [...state.hollidays, day];
            return {
                hollidays
            };
        })
    }

    render() {
        let tmp = this.generateHollidayForm();
        const { error, isLoading, name, address, description, status, creating, openingHours, postCode, refNumber, order } = this.state;
        if (status === 404) {
            return (<div className="Backend">
                <h1>404</h1>
            </div>)
        }
        else {
            return (
                <div className="Backend">
                    <Container>
                        <Row><Col><h1 className="Title">Thrifty sendibílaleiga {creating ? ('búa til staðsetningu') : ('breyta staðsetningu')}</h1></Col></Row>
                        {!isLoading ? (

                            <div>
                                <form onSubmit={this.onSubmit}>
                                    <Tabs defaultActiveKey="location" id="locationAddEditTab">
                                        <Tab eventKey="location" title="Staðsetningar">

                                            <label htmlFor="locationName">Nafn</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="name" id="locationName" value={name} onChange={this.onNameChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="locationAddress">Heimilisfang</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="address" id="locationAddress" value={address} onChange={this.onAddressChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="locationPostCode">Póst Númer</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="postcode" id="locationPostCode" value={postCode} onChange={this.onPostCodeChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="locationRefNumber">DAX RefNumber</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="refNumber" id="locationRefNumber" value={refNumber} onChange={this.onRefNumberChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="catOrder">Röðun</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="order" id="catOrder" value={order} onChange={this.onOrderChange} ></input> </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}><label htmlFor="weekDaysOpen">Virkirdagar opnar</label></Col>
                                                <Col xs={4}><label htmlFor="weekDaysClose" >Virkirdagar lokar</label></Col>
                                                <Col xs={4}><label htmlFor="weekDaysClosed" >Virkir dagar lokað</label></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}><TimePicker defaultValue={moment()} allowEmpty={false} showSecond={false} id="weekDaysOpen" value={!openingHours.weekDaysOpen ? null : moment.utc(openingHours.weekDaysOpen, 'HH:mm')} onChange={this.onWeekdaysOpenChange} /> </Col>
                                                <Col xs={4}><TimePicker defaultValue={moment()} allowEmpty={false} showSecond={false} id="weekDaysClose" value={!openingHours.weekDaysClose ? null : moment.utc(openingHours.weekDaysClose, 'HH:mm')} onChange={this.onWeekDaysCloseChange} /> </Col>
                                                <Col xs={4}><input name="weekdaysClosed" type="checkbox" checked={openingHours.weekDaysClosed} onChange={this.onWeekdaysClosedChange} /> </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}><label htmlFor="satDaysOpen">Laugardagar opnar</label></Col>
                                                <Col xs={4}><label htmlFor="satDaysClose" >Laugardagar lokar</label></Col>
                                                <Col xs={4}><label htmlFor="satDaysClosed" >Laugardagar lokað</label></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}><TimePicker defaultValue={moment()} allowEmpty={false} showSecond={false} id="satDaysOpen" value={!openingHours.saturdayOpen ? null : moment.utc(openingHours.saturdayOpen, 'HH:mm')} onChange={this.onSatDaysOpenChange} /> </Col>
                                                <Col xs={4}><TimePicker defaultValue={moment()} allowEmpty={false} showSecond={false} id="satDaysClose" value={!openingHours.saturdayClose ? null : moment.utc(openingHours.saturdayClose, 'HH:mm')} onChange={this.onSatDaysCloseChange} /> </Col>
                                                <Col xs={4}><input name="satDaysClosed" type="checkbox" checked={openingHours.saturdayClosed} onChange={this.onSatDaysClosedChange} /> </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}><label htmlFor="sunDaysOpen">Sunnudagar opnar</label></Col>
                                                <Col xs={4}><label htmlFor="sunDaysClose" >Sunnudagar lokar</label></Col>
                                                <Col xs={4}><label htmlFor="sunDaysClosed" >Sunnudagar lokað</label></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}><TimePicker defaultValue={moment()} allowEmpty={false} showSecond={false} id="sunDaysOpen" value={!openingHours.sundayOpen ? null : moment.utc(openingHours.sundayOpen, 'HH:mm')} onChange={this.onSunDaysOpenChange} /> </Col>
                                                <Col xs={4}><TimePicker defaultValue={moment()} allowEmpty={false} showSecond={false} id="sunDaysClose" value={!openingHours.sundayClose ? null : moment.utc(openingHours.sundayClose, 'HH:mm')} onChange={this.onSunDaysCloseChange} /> </Col>
                                                <Col xs={4}><input name="sunDayClosed" type="checkbox" checked={openingHours.sundayClosed} onChange={this.onSunDaysClosedChange} /> </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={2}><label>Frídagar</label></Col>
                                                <Col xs={2}><button type="button" onClick={this.addHolliday}>Bæta við</button></Col>
                                            </Row>
                                            {tmp}
                                            <label htmlFor="Description">Lýsing</label>
                                            <Row>
                                                <Col><textarea name="Description" className="form-control" rows="10" id="Description" value={description} onChange={this.onDescriptionChange}></textarea></Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                    <Row>
                                        <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                                        <Col xs={2}><button className="submitButton" type="submit">SUBMIT</button></Col>
                                    </Row>
                                </form>
                            </div>
                            // If there is a delay in data, let's let the user know it's loading
                        ) : (
                                <h3>Loading...</h3>
                            )}
                    </Container>
                </div>
            );
        }
    }
}

export default LocationAddEdit;