import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import image from '../../Images/bookButton.png';
import { Link } from 'react-router-dom'
import './FrontPage.css';

class FrontBookingBar extends Component {
    render() {
        return (
            <Container fluid={true}>
                <Row>
                    <Col style={{ 'paddingLeft': 0, 'paddingRight': 0 }}>
                        <div className="FrontBorder">
                            <div style={{
                                height: "100%",
                                backgroundImage: `url(${this.props.image})`,
                                "backgroundSize": "cover",
                                "backgroundPosition": "center center",
                                "backgroundRepeat": "no-repeat",
                            }}>
                                <Link to="/allir-bilar"><div className="BookNowButton"> <img src={image} alt="book button" onClick={this.setRedirect} /></div></Link>
                            </div>
                        </div>
                    </Col >
                </Row>
            </Container>
        );
    }
};



export default FrontBookingBar;