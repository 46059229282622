import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Category.css';


class Car extends Component {

  makeLocationList(item) {
    return (<div key={item._id}><FontAwesomeIcon icon="map-marker-alt" />{' ' + item.name}</div>)
  }

  render() {
    const { image, locations, description, name } = this.props.car;
    const { allLocations } = this.props;
    if (allLocations) {
      return (
        <Container className="Car">
          <Row> <Col className="Pic">
            <img src={image} alt={name}/>
          </Col>
          </Row>
          <div className="infoOfCar">
            <h5 className="name">{name}</h5>
          </div>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          <p style={{ fontWeight: "bold" }}>Þessi bíll er í boði á eftirtöldum stöðum</p>
          <Row>
            <Col md={8}>
              {allLocations.filter(item => locations.includes(item._id)).sort((a, b) => a.order - b.order).map(this.makeLocationList.bind(this))}
            </Col>
            <Col md={4}>
              <Link to={'/til-leigu-sendibilar?car=' + this.props.car._id + '&location=' + locations[0]}><button className="_btn">Skoða verð</button></Link>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (null)
    }

  }
};



export default Car;