import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './Backend.css';
import { Container, Row, Col, } from 'react-bootstrap';

require('dotenv').config();

class Backend extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="Backend">
                <Container>
                    <Row><Col><h1 className="Title">Thrifty sendibílaleiga stjórnborð</h1></Col></Row>
                    <Row>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/cars'><button className="Button">Bílar</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/categories'><button className="Button">Flokkar</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/locations'><button className="Button">Staðsetning</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/sites'><button className="Button">Síður</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/prices'><button className="Button">Verð</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/offers'><button className="Button">Tilboð</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/extras'><button className="Button">Aukahlutir</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/bookings'><button className="Button">Bókanir</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/requests'><button className="Button">Fyrirspurnir</button></Link></Col>
                        <Col xs={12} md={6} xl={4}><Link to='/admin/users'><button className="Button">Notendur</button></Link></Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Backend;