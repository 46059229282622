import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col, } from 'react-bootstrap';
import './Main.css';

class NoMatch extends Component {

  render() {
    return (
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"404 | Sendibílar til leigu"}</title>
          <meta name="keywords" content={"404 not found"} />
          <meta name="description" content={"Sendibílar til leigu er vefur þar sem þú getur bókað ódýra sendibíla til leigu með stuttum fyrirvara. Sjáðu lausa bíla á netinu og bókaðu strax. Auðvelt og þægilegt. Bókaðu núna."} />
        </Helmet>
        <Row className="justify-content-center">
          <Col md="auto" className="NoMatch">
            <Row ><Col><h3>Úps villa 404!</h3></Col></Row>
            <Row><Col><h3>Við biðjumst velvirðingar</h3></Col></Row>
            <Row><Col><p>Umbeðin síða finnst því miður ekki, þetta getur stafað af eftirfarandi atriðum:</p></Col></Row>
            <Row><Col><p>Vefsíðan hefur hugsanlega verið færð annað</p></Col></Row>
            <Row><Col><p>Upplýsingarnar gætu hafa verið fjarlægðar</p></Col></Row>
            <Row><Col><p>Vefslóðin sem þú fylgdir gæti hafa verið rangt skrifuð</p></Col></Row>
          </Col>
        </Row>
      </Container>
    );
  }

};



export default NoMatch;