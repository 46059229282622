import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
//import Calendar from 'react-calendar';
import Select from 'react-select';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/is';
import './Booking.css';
moment.locale('is');

var controller, signal;
//moment.tz.add('America/Los_Angeles|PST PDT|80 70|0101|1Lzm0 1zb0 Op0');

require('dotenv').config();

class Booking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            carId: '',
            selectedTime: { value: 2, label: '2 tímar' },
            timeDrop: [{ value: 2, label: '2 tímar' }, { value: 4, label: '4 tímar' }, { value: 8, label: '8 tímar' }, { value: 24, label: '24 tímar' }],
            selectedStartTime: null,
            selectedEndTime: null,
            selectedCarId: null,
            selectedLocationId: null,
            cars: [],
            allLocations: [],
            carsAvailable: [],
            prices: [],
            offers: [],
            extras: [],
            checkedExtras: [],
            checkedInsurance: [],
            curOffer: null,
            rentalPrice: 0,
            extraPrice: 0,
            insurancePrice: 0,
            image: '',
            description: '',
            selectedCar: null,
            selectedLocation: null,
            isLoading: true,
            isUploading: false,
            gettingAvailability: true,
            error: null,
            status: 200,
            date: new Date(),
            closed: false,
            queryString: true,
        }
        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        this.fetchPrices()
        await this.fetchCars()
        await this.fetchLocations()
        const values = await queryString.parse(this.props.location.search)
        this.setState({
            selectedCarId: values.car,
            selectedLocationId: values.location,
            gettingAvailability: (values.car && values.location)
        }, async () => {
            this.fetchAvailability();
            this.fetchOffers();
            this.fetchExtras();
            //await this.fetchAll();
            if (values.location) { this.setSelectedLocation(values.location); }
            else { this.setState({ queryString: false }); }
        });
    }

    fetchAll() {
        return Promise.all([this.fetchLocations(), this.fetchCars()])
    }

    /*componentWillReceiveProps(newProps) {
        const values = queryString.parse(newProps.location.search)
        this.setState({
            selectedCarId: values.car,
            selectedLocationId: values.location,
        });
    }*/

    fetchCars() {
        return fetch(process.env.REACT_APP_NODE_BACKEND + '/cars')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this._isMounted && this.setState({
                    cars: data,
                    //isLoading: false,
                });
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchPrices() {
        return fetch(process.env.REACT_APP_NODE_BACKEND + '/prices')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this._isMounted && this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this._isMounted && this.setState({
                    prices: data,
                    //isLoading: false,
                })
                
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchOffers() {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/offers')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this._isMounted && this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this._isMounted && this.setState({
                    offers: data,
                    //isLoading: false,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchExtras() {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/extras')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this._isMounted && this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this._isMounted && this.setState({
                    extras: data,
                    //isLoading: false,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchLocations() {
        return fetch(process.env.REACT_APP_NODE_BACKEND + '/locations')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this._isMounted && this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this._isMounted && this.setState({
                    allLocations: data,
                    //isLoading: false,
                });

            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    async fetchAvailability() {
        controller = new AbortController();
        signal = controller.signal;
        const { selectedCar, selectedLocation, date, allLocations, selectedLocationId, selectedTime } = this.state;
        const location = allLocations.find(item => item._id === selectedLocationId);
        let startDate = moment(date);
        let endDate = moment(date);
        let holliday = false;
        let closed = false;

        if (location) {
            //date.setHours(8);
            if (location.hollidays) {
                await location.hollidays.forEach(element => {
                    if (element.day === moment(date).date() && element.month === moment(date).month() + 1) {
                        holliday = true;
                        closed = element.closed;
                        let tmpOpen = element.open.split(':');
                        let tmpClose = element.close.split(':');
                        startDate = moment(date).hours(tmpOpen[0]).minute(tmpOpen[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
                        endDate = moment(date).hours(tmpClose[0]).minutes(tmpClose[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    }
                });
            }


            if (!holliday) {
                if (moment(date).day() === 6) {
                    closed = await location.openingHours.saturdayClosed;
                    let tmpOpen = location.openingHours.saturdayOpen.split(':');
                    let tmpClose = location.openingHours.saturdayClose.split(':');
                    startDate = moment(date).hours(tmpOpen[0]).minute(tmpOpen[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    endDate = moment(date).hours(tmpClose[0]).minutes(tmpClose[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
                } else if (moment(date).day() === 0) {
                    closed = await location.openingHours.sundayClosed;
                    let tmpOpen = location.openingHours.sundayOpen.split(':');
                    let tmpClose = location.openingHours.sundayClose.split(':');
                    startDate = moment(date).hours(tmpOpen[0]).minute(tmpOpen[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    endDate = moment(date).hours(tmpClose[0]).minutes(tmpClose[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
                } else {
                    closed = await location.openingHours.weekDaysClosed;
                    let tmpOpen = location.openingHours.weekDaysOpen.split(':');
                    let tmpClose = location.openingHours.weekDaysClose.split(':');
                    startDate = moment(date).hours(tmpOpen[0]).minute(tmpOpen[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    endDate = moment(date).hours(tmpClose[0]).minutes(tmpClose[1]).seconds(0).format("YYYY-MM-DDTHH:mm:ss[Z]");

                }
            }
            this.setState({ openTime: startDate, closeTime: endDate })
        }

        if (selectedCar && !closed) {
            this._isMounted && this.setState({ gettingAvailability: true, isLoading: true, error: null })
            fetch(process.env.REACT_APP_NODE_BACKEND + '/dax/availability/?carClass=' + selectedCar.klass + '&dateFrom=' + startDate + '&dateTo=' + endDate + '&location=' + selectedLocation.locationCode + '&rentLength=' + selectedTime.value + '&interval=30', { signal })
                // We get the API response and receive data in JSON format...
                .then(response => {
                    if (!response.ok) {
                        let error = {};
                        if (response.status === 500) {
                            error = { message: "Eitthvað fór úrskeiðis og ekki tókst að finna tíma vinsamlegast <a href='../hafa-samband'>hafðu samband</a> til að klára bókun", status: response.status };
                        } else {
                            error = { message: response.statusText, status: response.status };
                        }
                        throw (error)
                    }
                    this._isMounted && this.setState({ status: response.status })
                    return response.json();

                })
                // ...then we update the users state
                .then(data => {
                    this._isMounted && this.setState({
                        gettingAvailability: false,
                        availability: data,
                        isLoading: false,
                        selectedStartTime: null,
                        selectedEndTime: null,
                    })
                })
                // Catch any errors we hit and update the app
                .catch(error => {
                    if (error.message !== "The user aborted a request.") {
                        this.setState({ error: error, isLoading: false, gettingAvailability: false, availability: null })
                    }
                });
        } else {
            this.setState({
                availability: null,
                gettingAvailability: false,
                isLoading: false,
                selectedStartTime: null,
                selectedEndTime: null,
            })
        }
    }

    setSelectedCar(id) {
        const { cars, selectedLocationId, selectedLocation } = this.state;
        const result = cars.find(item => item._id === id.toString());
        let car = { value: result._id, label: result.name, klass: result.class }
        if (selectedLocation) {
            this.setState({ selectedCar: car, image: result.image, description: result.description, selectedCarId: id, gettingAvailability: true }, () => {
                this.getRentalPrice();
                this.fetchAvailability();
                this.props.history.push({ pathname: '/til-leigu-sendibilar', search: '?car=' + id + '&location=' + selectedLocationId })
            })
        }
    }
    async setSelectedLocation(id) {
        const { cars, allLocations, selectedCarId, selectedCar } = this.state;
        let availCars = await cars.filter(item => item.locations.includes(id))
        let result = await allLocations.find(item => item._id === id.toString());
        if (result) {
            let location = { value: result._id, label: result.name, locationCode: result.refNumber, address: result.address, postCode: result.postCode, description: result.description }
            this.setState({ selectedLocation: location, selectedLocationId: result._id, carsAvailable: availCars, gettingAvailability: true }, () => {
                this.getRentalPrice();
                this.fetchAvailability();
                if (!availCars.find(item => item._id === selectedCarId)) {
                    if (availCars[0]) {
                        this.setSelectedCar(availCars[0]._id);
                    }
                } else if (!selectedCar) { this.setSelectedCar(selectedCarId) }
                else { this.props.history.push({ pathname: '/til-leigu-sendibilar', search: '?car=' + selectedCarId + '&location=' + result._id }) }
            })
        } else {
            this.setState({ selectedCarId: null, selectedLocationId: null, gettingAvailability: false }, () => {
                this.props.history.push('/til-leigu-sendibilar');
            })
        }

    }

    async getRentalPrice() {
        const { prices, offers, date, selectedTime, selectedCarId, selectedLocationId } = this.state;
        if (offers && prices) {
            let curSeason = await prices.find(item => new Date(item.dateFrom) <= date && new Date(item.dateTo) >= date && item.default === false);
            let curOffer = await offers.find(item => new Date(item.dateFrom) <= date && new Date(item.dateTo) >= date && (item.cars.includes(selectedCarId) || item.locations.includes(selectedLocationId)));
            if (!curSeason) {
                curSeason = prices.find(item => item.default === true)
            }
            let priceForCar = null;
            let pricePerUnit = null;
            if (curSeason) { priceForCar = await curSeason.price.find(item => item.car === selectedCarId); }
            if (priceForCar) { pricePerUnit = priceForCar.prices.find(item => selectedTime.value >= item.minTime && item.maxTime >= selectedTime.value) }
            if (curOffer && curSeason && priceForCar) {
                let discount = 1 - (curOffer.discount / 100);
                this.setState({ curOffer: curOffer, rentalPrice: (pricePerUnit.price * selectedTime.value) * discount });
            }
            else if (curSeason && priceForCar) {
                this.setState({ curOffer: null, rentalPrice: (pricePerUnit.price * selectedTime.value) });
            }
        }
    }

    onDateChange = date => {
        if (!this.state.gettingAvailability && this.state.selectedCarId) {
            this.setState({ date: date, gettingAvailability: true }, () => {
                this.fetchAvailability();
                this.getRentalPrice();
            });
        }
        else if (!this.state.gettingAvailability) {
            this.setState({ date: date })
        }
        else if (this.state.gettingAvailability) {
            controller.abort();
            this.setState({ date: date, gettingAvailability: true, isLoading: true }, () => {
                this.fetchAvailability();
                this.getRentalPrice();
            });
            //this.fetchAvailability();
            //this.getRentalPrice();
        }
    }

    onDropdownChange = (event) => {
        this.setState({ cars: event.target.value });
    }

    handleCarChange = (event) => {
        if (this.state.gettingAvailability) {
            controller.abort();
        }
        this.setSelectedCar(event.value);
    }
    handleLocationChange = (event) => {
        if (this.state.gettingAvailability) {
            controller.abort();
        }
        this.setSelectedLocation(event.value);
    }

    handleTimeChange = (event) => {
        if (this.state.gettingAvailability) {
            controller.abort();
        }
        this.setState({ selectedTime: event, selectedStartTime: null }, () => {
            this.fetchAvailability();
            this.getRentalPrice();
        });
    }

    createTimeSelect() {
        const { allLocations, selectedLocationId, date, availability, gettingAvailability, selectedStartTime } = this.state;
        const location = allLocations.find(item => item._id === selectedLocationId);
        let buttons = { before: [], after: [] };
        if (location && !gettingAvailability && availability) {
            const times = availability.Sendi_VehAvailRS.AvailableTimes.Time;
            if (times) {
                times.forEach(time => {
                    const carId = time.Car._attributes.id;
                    const passedTime = moment(time.Start) < moment().add(15, 'minutes');
                    let valid = carId !== "";
                    if (passedTime === true) { valid = false }
                    const timeStart = moment(time.Start).format('HH:mm');
                    const timeEnd = moment(time.End).format('HH:mm')
                    if (valid) {
                        if (moment(time.Start).isAfter(moment(date).hour(12).minute(0).seconds(0))) {
                            buttons.after.push(<Col sm={6} lg={4} xl={3} key={time.Start}><button value={time.Start} onClick={this.handleTimeClick.bind(this, carId)} className="submitButton" style={selectedStartTime === time.Start ? ({ backgroundColor: 'rgb(0, 94, 184)' }) : ({})} type="submit">{timeStart} - {timeEnd}</button></Col>);
                        }
                        else {
                            buttons.before.push(<Col sm={6} lg={4} xl={3} key={time.Start}><button value={time.Start} onClick={this.handleTimeClick.bind(this, carId)} className="submitButton" style={selectedStartTime === time.Start ? ({ backgroundColor: 'rgb(0, 94, 184)' }) : ({})} type="submit">{timeStart} - {timeEnd}</button></Col>);
                        }
                    }
                });
            }
        }
        return buttons;
    }

    handleTimeClick(carId, event) {
        const { selectedTime } = this.state;
        const pickupDate = moment(event.target.value);
        this.setState({ carId: carId, selectedStartTime: event.target.value, currentPickupDate: pickupDate.format("YYYY-MM-DDTHH:mm:ss[Z]"), currentReturnDate: pickupDate.add(selectedTime.value, "hours").format("YYYY-MM-DDTHH:mm:ss[Z]") });
        this.getRentalPrice();
    }

    createCarDropDown(car) {
        return { value: car._id, label: car.name }
    }

    createLocationDropDown(location) {
        return { value: location._id, label: location.name }
    }

    onExtraChange(event) {
        let checkedArray = this.state.checkedExtras;
        let selectedValue = event.target.id;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                checkedExtras: checkedArray,
                extraPrice: this.state.extraPrice + parseInt(event.target.value),
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                checkedExtras: checkedArray,
                extraPrice: this.state.extraPrice - parseInt(event.target.value),
            });
        }
    }
    onInsuranceChange(event) {
        let checkedArray = this.state.checkedInsurance;
        let selectedValue = event.target.id;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                checkedInsurance: checkedArray,
                insurancePrice: this.state.insurancePrice + parseInt(event.target.value),
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                checkedInsurance: checkedArray,
                insurancePrice: this.state.insurancePrice - parseInt(event.target.value),
            });
        }
    }

    createExtraList(extra) {
        return (<Row key={extra._id}>
            <Col xs={12}>
                <input type="checkbox" value={extra.price} id={extra._id} onChange={this.onExtraChange.bind(this)} /><label htmlFor={extra._id}>{extra.name}</label>
            </Col>
        </Row>)
    }

    createInsuranceList(insurance) {
        return (<Row key={insurance._id}>
            <Col xs={12}>
                <input type="checkbox" value={insurance.price} id={insurance._id} onChange={this.onInsuranceChange.bind(this)} /><label htmlFor={insurance._id}>{insurance.name}</label>
            </Col>
        </Row>)
    }

    bookCar() {
        const { carId, selectedStartTime, currentPickupDate, currentReturnDate, selectedCar, selectedCarId, selectedLocation, selectedLocationId, image, description, rentalPrice, extraPrice, insurancePrice, checkedExtras, checkedInsurance, extras, selectedTime, openTime, closeTime } = this.state;
        let tmpExtras = extras.filter(item => checkedExtras.includes(item._id));
        let tmpInsurance = extras.filter(item => checkedInsurance.includes(item._id));
        if (!selectedLocation || !selectedLocationId) {
            let error = { message: 'Vinsamlegast veldu staðsetningu' }
            this.setState({ error: error });
        } else if (!selectedCar || !selectedCarId) {
            let error = { message: 'Vinsamlegast veldu bíl' }
            this.setState({ error: error });
        } else if (!selectedStartTime || !currentPickupDate) {
            let error = { message: 'Vinsamlegast veldu tíma' }
            this.setState({ error: error });
        }
        else {

            this.props.history.push({
                pathname: '/til-leigu-sendibilar/upplysingar',
                state: {
                    car: {
                        name: selectedCar.label,
                        carClass: selectedCar.klass,
                        image: image,
                        description: description,
                        id: selectedCarId,
                        carId: carId
                    },
                    price: {
                        carPrice: rentalPrice,
                        extraPrice: extraPrice,
                        insurancePrice: insurancePrice,
                    },
                    location: {
                        id: selectedLocationId,
                        name: selectedLocation.label,
                        address: selectedLocation.address,
                        postCode: selectedLocation.postCode,
                        description: selectedLocation.description,
                        locationCode: selectedLocation.locationCode
                    },
                    time: {
                        pickup: currentPickupDate,
                        return: currentReturnDate
                    },
                    extras: {
                        extrasSelected: tmpExtras,
                        insuranceSelected: tmpInsurance,
                    },
                    cache: {
                        length: selectedTime.value,
                        openTime: openTime,
                        closeTime: closeTime,
                    }
                }
            });
        }
    }

    render() {
        const { queryString, isLoading, selectedStartTime, image, description, allLocations, selectedCar, selectedLocation, carsAvailable, selectedTime, timeDrop, rentalPrice, extraPrice, insurancePrice, curOffer, gettingAvailability, extras, date, error, currentPickupDate, currentReturnDate } = this.state;
        let before = [];
        let after = [];
        let timeList = { before, after };
        const minDate = moment(new Date()).tz("gmt")
        const calMinDate = minDate.format();
        const maxDateMoment = minDate.add(1, 'months');
        const maxDate = new Date(maxDateMoment.format());
        if (carsAvailable) {
            var carsDrop = carsAvailable.sort((a, b) => a.order - b.order).map(this.createCarDropDown.bind(this));
        }
        if (allLocations) {
            var locationDrop = allLocations.sort((a, b) => a.order - b.order).map(this.createLocationDropDown.bind(this));
        }
        if (extras) {
            var extrasList = extras.filter(item => item.accessories === true).map(this.createExtraList.bind(this));

            var insuranceList = extras.filter(item => item.insurance === true).map(this.createInsuranceList.bind(this));
        }
        if (carsDrop && locationDrop && !gettingAvailability) {
            timeList = this.createTimeSelect();
        }
        if (date) {
            var displayDate = moment(date).locale('is').format('dddd DD.MM.YYYY');
        }
        return (
            
            <Container>
                {(selectedLocation && selectedCar) || !queryString ? (
                    <div>
                        <h2>Dagsetning og ökutæki</h2>
                        <Row >
                            <Col>
                                <Row >

                                    <Col xs={12} sm={6}>
                                        <label className="mainlabel" htmlFor="Location">Veldu staðsetningu</label>
                                        <Select value={selectedLocation} onChange={this.handleLocationChange} options={locationDrop} placeholder="Staðsetning" id="Location" />
                                    </Col>

                                    <Col>
                                        <label className="mainlabel" htmlFor="Time">Veldu lengd leigu</label>
                                        <Select value={selectedTime} onChange={this.handleTimeChange} options={timeDrop} placeholder="Tími" id="Time" />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs={12} sm={{ span: 6, order: 2 }}> <p className="infotext">Þarftu bíl í lengri tíma? Sendu okkur póst á <a href="mailto:sendibilar@sendibilartilleigu.is">sendibilar@sendibilartilleigu.is</a></p></Col>
                                    <Col xs={12} sm={6}>
                                        <label className="mainlabel" htmlFor="Car">Veldu bíl</label>
                                        <Select value={selectedCar} onChange={this.handleCarChange} options={carsDrop} placeholder="Bíll" id="Car" /> </Col>

                                </Row>
                                <Row >
                                    <Col xs={12} sm={6}> <img src={image} alt={""} /> <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                    </Col>
                                    <Col xs={12} sm={6} >
                                        <label className="mainlabel" htmlFor="Date">Veldu dag</label>
                                        <DatePicker
                                            selected={this.state.date}
                                            onChange={this.onDateChange}
                                            minDate={new Date(calMinDate)}
                                            maxDate={maxDate}
                                            inline
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center"><Col md='auto'><h3>{displayDate}</h3></Col></Row>
                        <Row className="justify-content-md-center"><Col md='auto'><h4>{timeList.before ? ("Veldu lausan tíma hér fyrir neðan") : ("Engir tímar fundust fyrir þennan dag")}</h4></Col></Row>
                        <Col xs={8} sm={12}>{error ? <p className="Error" dangerouslySetInnerHTML={{ __html: error.message }}></p> : " "}</Col>
                        <Row style={{ borderTop: '1px solid black', paddingTop: '10px' }}>
                            <Col>
                                {/*gettingAvailability && selectedLocation && selectedCar &&*/ isLoading ? ((<Row className="justify-content-sm-center"><Col sm="auto" className="timeSelect"><ReactLoading type={"spin"} color={"#5b5b5b"} height={100} width={150} /></Col></Row>)) : (
                                    <Row>
                                        <Col className="timeSelect">
                                            <Row><Col><h3>Tímar fyrir hádegi</h3></Col></Row>
                                            {timeList.before.length === 0 && !gettingAvailability ? (<h4>Engir lausir tímar</h4>) : (<Row>{timeList.before}</Row>)}

                                        </Col>
                                        <Col className="timeSelect">
                                            <Row><Col><h3>Tímar eftir hádegi</h3></Col></Row>
                                            {timeList.after.length === 0 && !gettingAvailability ? (<h4>Engir lausir tímar</h4>) : (<Row>{timeList.after}</Row>)}
                                        </Col>
                                    </Row>
                                )}
                            </Col>

                        </Row>
                        {selectedStartTime && selectedCar && currentPickupDate && currentReturnDate ? (<Row className="justify-content-md-center"><Col md='auto'><p style={{ fontWeight: 'bold' }}>Þú hefur valið {selectedCar.label} frá {moment(currentPickupDate).format('HH:mm DD.MM.YYYY')} til {moment(currentReturnDate).format('HH:mm DD.MM.YYYY')}</p></Col></Row>) : (null)}
                        <Row style={{ borderTop: '1px solid black', paddingTop: '10px' }}></Row>
                        <Row><Col md={12}><h4>Innifalið í leiguverði:</h4></Col></Row>
                        <Row><Col>
                            <ul>
                                <li>Rafmagn (hleðsla)</li>
                                <li>Virðisaukaskattur 24%</li>
                                <li>Vegaskattur (600 kr að lágmarki)</li>
                                <li>100 km akstur fyrir hvern leigudag. Hægt er að semja um <a href='../innifalinn-akstur'>umfram akstur</a></li>
                                <li>Ábyrgðar- og kaskótrygging (CDW) með sjálfsábyrgð kr. 400.000 en <a href='../tryggingar'>sjálfsábyrgð er hægt að lækka gegn vægu gjaldi.</a> </li>
                            </ul>
                        </Col></Row>

                        <Row style={{ borderTop: '1px solid black', paddingTop: '10px' }}>
                            <Col md={6}>
                                <Row><Col><h4>Tryggingar</h4></Col></Row>
                                {insuranceList}
                                <Row><Col><h4>Aukahlutir</h4></Col></Row>
                                {extrasList}
                            </Col>
                            <Col md={6}>
                                <h4>Verð er með virðisaukaskatti</h4>
                                {curOffer ? (<h5>Tilboð  í gangi: {curOffer.name} afsláttur {curOffer.discount}%</h5>) : (null)}
                                <table style={{ "width": "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td >Leiguverð</td>
                                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={rentalPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            <td >ISK</td>
                                        </tr>
                                        <tr>
                                            <td>Tryggingar</td>
                                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={insurancePrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            <td >ISK</td>
                                        </tr>
                                        <tr>
                                            <td>Aukahlutir</td>
                                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={extraPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            <td>ISK</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>
                                                <strong>Samtals</strong>
                                            </td>
                                            <th style={{ "textAlign": "right" }}><strong><CurrencyFormat value={rentalPrice + insurancePrice + extraPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></strong></th>
                                            <th>ISK</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>
                        <Row style={{ borderTop: '1px solid black', paddingTop: '10px' }}>
                            <Col>
                                <h5>Ekki er tekið við fyrirframgreiddum kreditkortum né debit kortum. Athugið að kreditkort verður að vera á nafni leigutaka og verður leigutaki að framvísa kreditkortinu sjálfu (físískt) en ekki er hægt að nota Apple Pay eða slíkt forrit. Leigutaka er heimilt að tilnefna ökumann í sinn stað ef sá ökumaður framvísar gildu ökuskírteini við undirritun leigusamnings.</h5>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={8} sm={10}>{error ? <p className="Error" dangerouslySetInnerHTML={{ __html: error.message }}></p> : " "}</Col>
                            <Col xs={4} sm={2}> <button className="submitButton" style={{ backgroundColor: 'rgb(0, 94, 184)' }} onClick={this.bookCar.bind(this)}>STAÐFESTA</button></Col>
                        </Row>
                    </div>) : (
                        <h3>Hleð...</h3>
                    )}
            </Container>
        );
    }
}



export default Booking;