const ImageService = {
    AddImage: function (image, folder) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("img", image[0]);

            fetch(process.env.REACT_APP_NODE_BACKEND + "/images", {
                method: "POST",
                credentials: 'include',
                body: formData
            }).then(res => res.json().then(json =>{
                resolve(json);
            })).catch(err => {
                reject(err);
            });
        })
    },

    AddSmallImage: function (image, folder) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("img", image[0]);

            fetch(process.env.REACT_APP_NODE_BACKEND + "/images/small", {
                method: "POST",
                credentials: 'include',
                body: formData
            }).then(res => res.json().then(json =>{
                resolve(json);
            })).catch(err => {
                reject(err);
            });
        })
    },

    DeleteImage: function (imageToDelete) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({url: imageToDelete});
            fetch(process.env.REACT_APP_NODE_BACKEND + "/images", {
                method: "DELETE",
                headers: new Headers({'content-type': 'application/json'}),
                credentials: 'include',
                body: body
            }).then(res => res.text().then(json =>{
                resolve(json);
            })).catch(err => {
                reject(err);
            });
        })
    }
};

export default ImageService;