import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Container, Row, Col, } from 'react-bootstrap';

require('dotenv').config();

class Backend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }
    componentDidMount() {

    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_NODE_BACKEND + '/users/authenticate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(this.state),
        })
            .then(res => {
                if (res.status === 200) {
                    res.text().then(token => {
                        const cookies = new Cookies();
                        cookies.set('token', token, { path: '/', maxAge: 3600, httpOnly: true, secure: true, sameSite: 'none'});
                    })
                    this.props.history.push('/admin');
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
                console.error(err);
                alert('Error logging in please try again');
            });
    }

    render() {
        return (
            <div className="Backend">
                <Container >
                    <form onSubmit={this.onSubmit}>
                        <Row><Col><h1>Innskráning</h1></Col></Row>
                        <Row><Col><input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Tölvupóstur"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            required
                        /></Col></Row>
                        <Row><Col><input
                            className="form-control"
                            type="password"
                            name="password"
                            placeholder="Lykilorð"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            required
                        /></Col></Row>
                        <Row><Col><input type="submit" value="Submit" /></Col></Row>
                    </form>
                </Container>
            </div>
        );
    }
}

export default Backend;