import React from 'react';
import ReactDOM from 'react-dom';
//import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';


if (!window.location.host.startsWith("www") && process.env.REACT_APP_ENV === 'production') {
    window.location = window.location.protocol + "//www." + window.location.host + window.location.pathname;
} else {
    ReactDOM.render((
        <App />
    ), document.getElementById('root'));
    /*const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
        hydrate(<App />, rootElement);
    } else { 
        render(<App />, rootElement);
    }*/
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
